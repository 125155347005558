* {
    box-sizing: border-box;
}
body {
    background-color: #090A0E;
    color: #ffffff;
}

.center-max-size {
    max-width: 1020px;
    margin: 0 auto;
    padding: 0 15px;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    text-transform: uppercase;
}

.poster {
    background-size: cover;
    background-position: top center;
    min-height: 600px;
    display: flex;
    align-items: flex-end;
    padding-bottom: 30px;
    position: relative;
}

.poster-content {
    width: 80%;
}

h1 {
    font-size: 92px;
    margin-top: 0;
    line-height: 1;
    margin-bottom: 10px;
}

.container {
    background-color: #0F1014;
    /*padding-top: 20px;*/
    padding-bottom: 20px;
    align-items: flex-end;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
    left: 50%;
    

}


.button {
    margin: 20px 0;
    background: #0F1014;
    color: white;
    border: none;
    outline: none;
    padding: 10px 15px;
    cursor: pointer;
    transition: box-shadow 0.2s;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: 1px solid gray;
}

.button:hover {
    box-shadow: 0 5px 5px rgba(0,0,0,0.5);
}

.close-video {
    position: absolute;
    z-index: 2;
    bottom: 10px;
    left: 10px;
}

.youtube-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.brand {
    font-weight: bold;
    left: 50%;
}

/*-- MOVIE */

.movie {
    position: relative;
    cursor: pointer;
    text-align: left;
    font-size: 16px;
}

.movie img {
    max-width: 100%;
}

.movie-infos {
    padding: 15px 0;
}

.movie-title {
    margin: 0;
}

.movie-voting {
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: #0F1014;
    border: 1px solid gray;
    padding: 10px;
    font-size: 12px;
    border-radius: 25px;
}

/** SEARCH **/
.form {
    justify-content: center;
    align-items: center;
    display: flex;
    padding-bottom: 20px;
}

.search {
    border: 1px solid darkslategray;
    outline: transparent;
    background-color: transparent;
    padding: 5px 10px;
    border-radius: 25px;
    color: white;
    align-items: center;
    

}

.submit-search {
    right: 5px;
    top: 5px;
    background-color: transparent;
    color:  white;
    border: none;
    cursor: pointer;
    width: 5%;
    border-radius: 25px;

}